import { useState } from 'react';

function CapitalsQuestion({ giveScore }) {
	const [amount, setAmount] = useState(0);

	return (
		<fieldset>
			<legend>Combien de capitales connaissez-vous ?</legend>
			<input type="number" min="0" value={amount} onChange={(ev) => setAmount(ev.target.valueAsNumber)}/>
			<input type="submit" value="Suivant" onClick={() => giveScore(Math.min(50, Math.max(0, amount - 10)))}/>
		</fieldset>
	);
}

export default CapitalsQuestion;
