import { useState } from 'react';

function OnlineQuestion({ giveScore }) {
	const [timeOnline, setTimeOnline] = useState(0);

	return (
		<fieldset>
			<legend>Combien d&apos;heures passez-vous en ligne par jour?</legend>
			<input type="number" min="0" max="24" value={timeOnline} onChange={(ev) => setTimeOnline(ev.target.valueAsNumber)}/>
			<input type="submit" value="Suivant" onClick={() => giveScore(timeOnline)}/>
		</fieldset>
	);
}

export default OnlineQuestion;
