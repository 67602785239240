import { Fragment, useState } from "react";


function NoiseQuestion({ giveScore }) {
	const [soundPlayed, setSoundPlayed] = useState(false);

	if (!soundPlayed) {
		return (
			<Fragment>
				<p>Veuillez mettre le volume au maximum et écouter ceci :</p>
				<audio src="./unpleasant.opus" controls onEnded={() => setSoundPlayed(true)}/>
			</Fragment>
		)
	}

	return (
		<fieldset>
			<legend>Avez-vous apprécié ?</legend>
			<label><input type="radio" id="Oui" name="noise" onChange={() => giveScore(0)} />
				Oui
			</label>
			<label><input type="radio" id="Non" name="noise" onChange={() => giveScore(0)} />
				Non
			</label>
			<label><input type="radio" id="Non" name="noise" onChange={() => giveScore(10)} />
				Non, et je vous hais
			</label>
		</fieldset>
	);
}

export default NoiseQuestion;
