import { useId } from 'react';

export function simpleQuestion(question, answers) {
	return function Question({ giveScore }) {
		const id = useId();

		return (
			<fieldset>
				<legend>{question}</legend>
				{Object.entries(answers).map(([answer, value]) => (
					<div key={answer}>
						<input type="radio" id={answer} name={id} value={value} onChange={() => giveScore(value)} />
						<label for={answer}>{answer}</label>
					</div>
				))}
			</fieldset>
		);
	}
}
