import { Fragment, useState } from 'react';

import { simpleQuestion } from './Question';
import './App.css';
import questions from './questions.json';
import NoiseQuestion from './questions/NoiseQuestion';
import OnlineQuestion from './questions/OnlineQuestion';
import CapitalsQuestion from './questions/CapitalsQuestion';

const questionComponents = questions.map(({ question, answers }) => simpleQuestion(question, answers));
questionComponents.push(OnlineQuestion);
questionComponents.push(NoiseQuestion);
questionComponents.push(CapitalsQuestion);

questionComponents.sort(() => Math.random() - 0.5);

function App() {
	const [score, setScore] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(0);

	function handleScore(value) {
		setScore(score + value);
		setCurrentQuestion(currentQuestion + 1);
	}

	const Question = currentQuestion < questionComponents.length ? questionComponents[currentQuestion] : null;

	return (
		<Fragment>
			<h1>Calculateur de granité</h1>
			<div className="app">
				{Question !== null ? (
					<Question
						giveScore={handleScore}
					/>
				) : (
					<div>
						<h2>Fin du questionnaire</h2>
						<p>Merci d&apos;avoir répondu à toutes les questions !</p>
						{score <= 0 ? (
							<Fragment>
								<p>Le monde grana vous est étranger</p>
								<p>Il faudrait songer à se <strong>déconstruire</strong>.</p>
							</Fragment>
						): (
							<Fragment>
								<p>Vous êtes un grana de niveau <span title={score}>G{Math.floor(score / 25)}</span>.</p>
							</Fragment>
						)}
					</div>
				)}
				<div className="granita-container">
					{new Array(Math.max(1, Math.ceil(score / 100))).fill(null).map((_, i) => (
						<div className="granita">
							<div className="granita-top" style={{
								clipPath: `rect(0 100% ${100*(i+1) - score}% 0)`
							}}>🍧</div>
							<div className="granita-bottom" style={{
								clipPath: `rect(${100*(i+1) - score}% 100% 100% 0)`
							}}>🍧</div>
						</div>
					))}
				</div>
			</div>
		</Fragment>
	);
}

export default App;
